import {graphql} from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import {Helmet} from 'react-helmet';
import Layout from '../components/layout';

export default function Template({
  data // this prop will be injected by the GraphQL query below.
}) {
  const {markdown, images} = data; // data.markdownRemark holds your post data
  const {frontmatter, html} = markdown;
  const {author, date, description, featuredImage, path, title} = frontmatter;
  const featuredImageFluid = featuredImage.childImageSharp.fluid;
  const isBlog = path.indexOf('blog') > -1;
  const authorImageFiles = images.edges.filter(edge => {
    return edge.node.name === author;
  });
  let articleDescription = date || description;
  if (isBlog) {
    articleDescription = `${author.toUpperCase()} / ${articleDescription}`;
  }
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description || title} />
      </Helmet>
      <div
        className="blog-header inner"
        style={{
          overflow: 'hidden',
          position: 'relative',
          marginTop: '-3.25em',
          display: 'flex',
          flexWrap: 'wrap',
          borderTop: 0
        }}
      >
        <Img className="header-image" fluid={featuredImageFluid} style={{width: '100%', position: 'absolute'}} />
        <div
          className="inner"
          style={{
            position: 'relative',
            top: '4em',
            marginBottom: '2em',
            zIndex: 2
          }}
        >
          <div>
            {authorImageFiles.length > 0 && authorImageFiles[0].node.childImageSharp && (
              <Img
                fixed={authorImageFiles[0].node.childImageSharp.fixed}
                style={{
                  marginRight: '2em',
                  verticalAlign: 'middle',
                  width: '150px',
                  height: '150px'
                }}
              />
            )}
            {authorImageFiles.length > 0 && !authorImageFiles[0].node.childImageSharp && (
              <img
                alt=""
                src={`..${authorImageFiles[0].node.publicURL}`}
                style={{
                  width: '150px',
                  height: '150px',
                  marginRight: '2em',
                  verticalAlign: 'middle'
                }}
              />
            )}
            <header
              className="major"
              style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                marginBottom: '0px',
                maxWidth: '100%'
              }}
            >
              <h1>{title}</h1>
              <p>{articleDescription}</p>
            </header>
          </div>
        </div>
      </div>
      <section style={{background: 'linear-gradient(rgba(0,0,0,0), #222)'}}>
        <div className="blog-post-container inner">
          <div className="blog-post">
            <div className="blog-post-content" dangerouslySetInnerHTML={{__html: html}} />
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdown: markdownRemark(frontmatter: {path: {eq: $path}}) {
      html
      frontmatter {
        author
        authorImage
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1600, maxHeight: 1200, fit: CONTAIN, quality: 70) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    }
    images: allFile(filter: {extension: {regex: "/(png|jpg|svg)/"}}) {
      edges {
        node {
          publicURL
          name
          childImageSharp {
            fixed(width: 150, height: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;
